import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  },
  avatarWhite: {
    backgroundColor: colors.common.white,
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  whiteIcon: {
    color: colors.common.white
  },
  cyanIcon: {
    color: colors.cyan[600]
  }
}));

const StatNombreMontant = ({
  className,
  couleur,
  libelle,
  valeur,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography
              gutterBottom
              variant="h6"
              style={{ color: couleur[600] }}
            >
              {libelle}
            </Typography>
            <Typography variant="h3" style={{ color: couleur[900] }}>
              {valeur}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

StatNombreMontant.propTypes = {
  className: PropTypes.string
};

export default StatNombreMontant;
