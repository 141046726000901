export const getToken = state => state.auth.token && state.auth.token.value;

export const getUser = state => state.auth;

export const getUserIdentifiant = state => state.auth.identifiant;

export const getUserNomAffichable = state => state.auth.nomAffichable;

export const getRefreshToken = state => state.auth.refreshToken;

export const isUserIsAdmin = state =>
  state.auth.roles ? state.auth.roles.includes('ROLE_ADMIN') : false;

export const isUserDirty = state => state.auth.dirty;

export const getActualMenuCode = state => state.auth.actualMenuCode;

export const getTokenDate = state =>
  state.auth.token && state.auth.token.expirationDate;

export function isTokenValid(state) {
  const date = getTokenDate(state);
  if (!date) {
    return false;
  }

  return new Date(date) - new Date() > 0;
}

export const getIdentifiants = state => state.auth.identifiantsGeneres;
