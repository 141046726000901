import { getConfig } from '../platform';

const baseApi = getConfig('apiUrl');

// eslint-disable-next-line
export default {
  'identifiants:lire': {
    url: baseApi + '/utilisateur/identifiants',
    method: 'GET',
    secure: true
  },
  'auth:login': {
    url: baseApi + '/utilisateur/connexion',
    method: 'POST'
  },
  'auth:refreshToken': {
    url: (refreshToken) =>
      baseApi + '/utilisateur/refreshToken?refreshToken=' + refreshToken,
    method: 'GET'
  },

  'commun:classe': {
    url: baseApi + '/commun/classe',
    method: 'GET',
    secure: true
  },
  'commun:etablissement': {
    url: baseApi + '/commun/etablissement',
    method: 'GET',
    secure: true
  },

  'sms:post': {
    url: baseApi + '/sms',
    method: 'POST',
    secure: true
  },
  'sms:statistique': {
    url: (data) => baseApi + '/sms/statistique/' + data.mois + '/' + data.annee,
    method: 'GET',
    secure: true
  }
};
