import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, identifiants, ...rest }) => {
  const classes = useStyles();

  const [limit] = useState(15);
  const [page, setPage] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(15);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setMin(newPage * limit);
    setMax((newPage + 1) * limit);
  };

  return (
    <>
      {identifiants && identifiants.length > 0 && (
        <Card className={clsx(classes.root, className)} {...rest}>
          <PerfectScrollbar>
            <Box minWidth={800}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Profil</TableCell>
                    <TableCell>Identifiant</TableCell>
                    <TableCell>Mot de passe</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {identifiants.slice(min, max).map(identifiant => (
                    <TableRow hover key={identifiant.identifiant}>
                      <TableCell>{identifiant.profil}</TableCell>
                      <TableCell>{identifiant.identifiant}</TableCell>
                      <TableCell>{identifiant.password}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={identifiants ? identifiants.length : 0}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[15]}
            labelRowsPerPage="Ligne par page"
            onPageChange={handlePageChange}
          />
        </Card>
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  identifiants: PropTypes.array
};

export default Results;
