import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// Reducers
import ApiReducer from './Api/Api.reducer';
import AuthReducer from '../views/auth/Auth.reducer';
import DashboardReducer from '../views/reports/Dashboard.reducer';
import SmsReducer from 'src/views/sms/Sms.reducer';

const reducers = {
  api: ApiReducer,
  auth: AuthReducer,
  dashboard: DashboardReducer,
  sms: SmsReducer
};

const rootReducer = history =>
  combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

export default rootReducer;

export function dehydrateState(state) {
  const dehydratedState = {};

  Object.keys(reducers).forEach(function(moduleName) {
    dehydratedState[moduleName] = {};

    const reducer = reducers[moduleName];
    if (reducer.dehydrate) {
      dehydratedState[moduleName] = reducer.dehydrate(state[moduleName]);
    }
  });

  return dehydratedState;
}

export function hydrateState(state) {
  const hydratedState = {};

  Object.keys(reducers).forEach(function(moduleName) {
    const reducer = reducers[moduleName];
    hydratedState[moduleName] = reducer.hydrate(state && state[moduleName]);
  });

  return hydratedState;
}
