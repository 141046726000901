import {
  LOGIN_USER,
  LOGOUT_USER,
  LIRE_IDENTIFIANTS,
  SET_ACTUAL_MENU
} from './Auth.constants';

const emptyState = {
  identifiant: null,
  nomAffichable: null,
  roles: null,
  state: null,
  token: null,
  refreshToken: null,
  identifiantsGeneres: null,
  actualMenuCode: 'DASHBOARD'
};

let initialState = emptyState;
try {
  const cache = JSON.parse(localStorage.getItem('auth'));
  if (cache) {
    initialState = cache;
  }
} catch (error) {}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      localStorage.removeItem('auth');

      return {
        ...state,
        ...emptyState
      };

    case LOGIN_USER:
      const auth = {
        identifiant: action.identifiant,
        roles: action.roles,
        nomAffichable: action.nomAffichable,
        token: action.token,
        refreshToken: action.refreshToken,
        dateExpirationJeton: action.dateExpirationJeton,
        dirty: false
      };
      localStorage.setItem('auth', JSON.stringify(auth));

      return {
        ...state,
        ...auth
      };
    case LIRE_IDENTIFIANTS:
      return {
        ...state,
        identifiantsGeneres: action.data
      };
    case SET_ACTUAL_MENU:
      return {
        ...state,
        actualMenuCode: action.code
      };

    default:
      return state;
  }
};

AuthReducer.hydrate = function() {
  return initialState;
};

export default AuthReducer;
