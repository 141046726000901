import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch
} from '@material-ui/core';

const SmsForm = ({ etablissements, save }) => {
  const [classes, setClasses] = useState([]);

  const sendSms = async values => {
    await save(values);
  };

  return (
    <Formik
      initialValues={{
        classe: '',
        etablissement: '',
        message: '',
        parent: ''
      }}
      validationSchema={Yup.object().shape({
        message: Yup.string().required('Veuillez saisir votre message'),
        etablissement: Yup.string().required(
          'Veuillez choisir au moins un établissement'
        )
      })}
      onSubmit={values => {
        sendSms({ ...values });
      }}
    >
      {({ setFieldValue, handleChange, handleSubmit, values, isValid }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Veuillez saisir le message et choisir un établissement ou une classe"
              title="Envoi de SMS"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.parent}
                            onChange={event => {
                              setFieldValue('parent', event.target.checked);
                            }}
                            color="secondary"
                            name="parent"
                          />
                        }
                        label="Envoi aux parents"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  {etablissements && (
                    <TextField
                      fullWidth
                      label="Etablissement"
                      name="etablissement"
                      onChange={event => {
                        if (!event.target.value.includes('--')) {
                          setFieldValue('etablissement', event.target.value);
                          let clas = etablissements
                            .filter(dep => dep.code === event.target.value)
                            .map(dep => dep.classes);
                          setClasses(clas[0]);
                        } else {
                          setFieldValue('etablissement', '');
                          setClasses([]);
                        }
                      }}
                      required
                      value={values.etablissement}
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true
                      }}
                    >
                      <option>-- Sélectionnez un établissement --</option>
                      {etablissements.map(option => (
                        <option key={option.code} value={option.code}>
                          {option.nom}
                        </option>
                      ))}
                    </TextField>
                  )}
                </Grid>
                {classes.length > 0 && (
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Classe"
                      name="classe"
                      onChange={handleChange}
                      value={values.classe}
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true
                      }}
                    >
                      <option value={null}>-- Toutes --</option>
                      {classes.map(option => (
                        <option key={option.code} value={option.code}>
                          {option.nom}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="SMS"
                    name="message"
                    onChange={handleChange}
                    required
                    value={values.message}
                    variant="outlined"
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 500 }}
                    helperText={`Nombre de caractère restant: ${500 -
                      values.message.length}`}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!isValid}
              >
                Envoyer
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

SmsForm.propTypes = {
  className: PropTypes.string,
  etablissements: PropTypes.array,
  save: PropTypes.func
};

export default SmsForm;
