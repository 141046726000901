import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const StatClasse = ({ className, datas, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Statistiques par classe" />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Etablissement</TableCell>
                <TableCell>Classe</TableCell>
                <TableCell>Nombre de contacts</TableCell>
                <TableCell>SMS envoyés</TableCell>
                <TableCell>Montant</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datas &&
                datas.map(data => (
                  <TableRow hover key={data.classe}>
                    <TableCell>{data.etablissement}</TableCell>
                    <TableCell>{data.classe}</TableCell>
                    <TableCell>{data.population}</TableCell>
                    <TableCell>{data.nombre}</TableCell>
                    <TableCell>{data.montant} FCFA</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

StatClasse.propTypes = {
  className: PropTypes.string
};

export default StatClasse;
