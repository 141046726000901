import { LOGOUT_USER } from '../auth/Auth.constants';
import { LOAD_ETABLISSEMENT } from './Sms.constants';

const initialState = {};

const SmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ETABLISSEMENT:
      return {
        ...state,
        etablissement: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

SmsReducer.hydrate = function () {
  return initialState;
};

export default SmsReducer;
