import { connect } from 'react-redux';
import VoteElecteurView from './SmsView.component';
import { listerEtablissements, envoyerSms } from '../Sms.actions';
import { getIsFetching, getIsFetched } from 'src/setup/Api/Api.selectors';
import { getEtablissements } from '../Sms.selectors';
import { updateActualMenu } from 'src/views/auth/Auth.actions';

function mapStateToProps(state) {
  return {
    etablissements: getEtablissements(state),
    fetching: getIsFetching(state, 'commun:departement'),
    fetchingSms: getIsFetching(state, 'sms:post'),
    fetched: getIsFetched(state, 'commun:departement')
  };
}

const mapDispatchToProps = {
  listerEtablissements,
  envoyerSms,
  updateActualMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(VoteElecteurView);
