import { push } from 'connected-react-router';
import {
  LOGIN_USER,
  LOGOUT_USER,
  LIRE_IDENTIFIANTS,
  SET_ACTUAL_MENU
} from './Auth.constants';
import { getRefreshToken, isTokenValid, getTokenDate } from './Auth.selectors';
import { fetchApiAction } from 'src/setup/Api/api';

function storeUserFromAPI(data) {
  return function(dispatch) {
    dispatch({
      type: LOGIN_USER,
      identifiant: data.identifiant,
      roles: data.roles,
      nomAffichable: data.nomAffichable,
      token: {
        value: data.token,
        expirationDate: data.dateExpirationJeton
      },
      refreshToken: data.refreshToken
    });
  };
}

export function authenticate() {
  return function(dispatch, getState) {
    const state = getState();
    if (!getTokenDate(state)) {
      return false;
    }
    if (!isTokenValid(state)) {
      dispatch(refreshToken());
    }
  };
}

export function refreshToken() {
  return function(dispatch, getState) {
    const token = getRefreshToken(getState());
    if (!token) {
      dispatch(push('/login'));
      return false;
    }

    dispatch(
      fetchApiAction(
        'auth:refreshToken',
        { url: token },
        storeUserFromAPI,
        function() {
          return function(dispatch) {
            dispatch(push('/login'));
          };
        }
      )
    );
  };
}

export function loginUser(user) {
  const body = {
    identifiant: user.identifiant,
    password: user.password
  };

  return fetchApiAction('auth:login', { body }, storeUserFromAPI);
}

export function lireIdentifiants() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('identifiants:lire', {}, result => ({
        type: LIRE_IDENTIFIANTS,
        data: result
      }))
    );
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}

export function updateActualMenu(code) {
  return {
    type: SET_ACTUAL_MENU,
    code
  };
}
