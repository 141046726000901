import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Provider, connect } from 'react-redux';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import store, { history } from './store';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import DashboardView from 'src/views/reports/DashboardView/DashboardView.container';
import LoginView from 'src/views/auth/LoginView.container';
import NotFoundView from 'src/views/errors/NotFoundView';
import SmsView from 'src/views/sms/SmsView/SmsView.container';
import { isTokenValid } from './views/auth/Auth.selectors';
import Toaster from './components/Toaster/Toaster.container';
import IdentifiantsView from './views/auth/Identifiants/IdentifiantsView.container';
import * as serviceWorker from './serviceWorker';

const Content = props => {
  return (
    <Routes>
      <Route
        path="app"
        element={
          props.isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />
        }
      >
        <Route path="dashboard" element={<DashboardView />} />
        <Route path="sms" element={<SmsView />} />
        <Route path="identifiants" element={<IdentifiantsView />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
      <Route path="/" element={<MainLayout />}>
        <Route
          path="/"
          element={
            <Navigate to={props.isLoggedIn ? 'app/dashboard' : '/login'} />
          }
        />
        <Route path="404" element={<NotFoundView />} />
        <Route path="login" element={<LoginView />} />
        <Route path="logout" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
    </Routes>
  );
};

const RouteContainer = connect(state => {
  return {
    isLoggedIn: isTokenValid(state)
  };
})(Content);

const App = () => (
  <Provider store={store}>
    <BrowserRouter history={history}>
      <ThemeProvider theme={theme}>
        <Toaster />
        <GlobalStyles />
        <RouteContainer />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);

serviceWorker.register();

export default App;
