import { connect } from 'react-redux';
import NavBar from './NavBar.component';
import { logoutUser, updateActualMenu } from 'src/views/auth/Auth.actions';
import {
  getUser,
  isUserIsAdmin,
  getActualMenuCode
} from 'src/views/auth/Auth.selectors';

function mapStateToProps(state) {
  return {
    user: getUser(state),
    isUserIsAdmin: isUserIsAdmin(state),
    actualMenuCode: getActualMenuCode(state)
  };
}

const mapDispatchToProps = {
  logoutUser,
  updateActualMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
