import React, { useEffect } from 'react';
import { Box, Container, makeStyles, LinearProgress } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ElecteurListView = ({
  lireIdentifiants,
  updateActualMenu,
  identifiants,
  fetching,
  fetched
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!identifiants && !fetching && !fetched) {
      lireIdentifiants();
    }
    updateActualMenu('ID');
  });

  return (
    <Page className={classes.root} title="Electeurs">
      <Container maxWidth={false}>
        {fetching && (
          <LinearProgress
            classes={{
              colorPrimary: 'primary'
            }}
          />
        )}
        <Box mt={3}>
          <Results identifiants={identifiants} />
        </Box>
      </Container>
    </Page>
  );
};

export default ElecteurListView;
