import { connect } from 'react-redux';
import DashboardView from './DashboardView.component';
import { getUser } from 'src/views/auth/Auth.selectors';
import { updateActualMenu } from 'src/views/auth/Auth.actions';
import { getDashboardData } from '../Dashboard.selectors';
import { requestDashboardData } from '../Dashboard.actions';
import { getIsFetching, getIsFetched } from 'src/setup/Api/Api.selectors';

function mapStateToProps(state) {
  return {
    user: getUser(state),
    dashboard: getDashboardData(state),
    fetching: getIsFetching(state, 'sms:statistique'),
    fetched: getIsFetched(state, 'sms:statistique')
  };
}

const mapDispatchToProps = {
  requestDashboardData,
  updateActualMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
