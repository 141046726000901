import { LOAD_DASHBOARD_DATAS } from './Dashboard.constants';
import { fetchApiAction } from 'src/setup/Api/api';

export function requestDashboardData(mois, annee) {
  return function(dispatch) {
    dispatch(
      fetchApiAction('sms:statistique', { url: { mois, annee } }, result => ({
        type: LOAD_DASHBOARD_DATAS,
        data: result
      }))
    );
  };
}
